<template>
  <div class="request-query-report">
    <sm-editable-list
      ref="smEditableList"
      :controllerName="controllerName"
      :breadcrumbs="breadcrumbs"
      :tableCaption="tableCaption"
      :tableHeaders="currentTableHeaders"
    >
      <template #top-panel>
        <button class="editable-list__top-panel-button" @click="refreshTable">
          <sm-icon name="refresh" />
          Обновить таблицу
        </button>
      </template>
      <template v-slot:actionButtons="{ row }">
        <div
          class="request-query-report__list-buttons editable-list__datatable-buttons"
        >
          <template v-if="showLogButton">
            <sm-tooltip text="Информация о задании">
              <button
                class="editable-list__datatable-button"
                @click="onShowInfo(row.id, row.baseName)"
              >
                <sm-icon name="information-circle" />
              </button>
            </sm-tooltip>
          </template>

          <template v-if="showDownloadFileButton">
            <sm-tooltip text="Cкачать файл">
              <button
                class="editable-list__datatable-button"
                @click="onDownloadFile(row.id)"
              >
                <sm-icon name="document-download" />
              </button>
            </sm-tooltip>
          </template>

          <template v-if="showRestartTasksButton">
            <sm-tooltip text="Перезапустить задание">
              <button
                class="editable-list__datatable-button"
                @click="onShowRestartTasks(row.id)"
              >
                <sm-icon name="refresh" />
              </button>
            </sm-tooltip>
          </template>
        </div>
      </template>
    </sm-editable-list>

    <sm-modal
      class="request-query-report__modal"
      :show="showInfoModal"
      modalTitle="Информация о задании"
      @close="onCloseInfoModal"
    >
      <template #body>
        <p class="request-query-report__modal-text">
          {{ infoModalText }}
        </p>
      </template>
    </sm-modal>

    <sm-modal
      class="request-query-report__modal"
      :show="showRestartTasks"
      modalTitle="Информация о задании"
      @close="onCloseRestartTasks"
    >
      <template #body>
        <p class="request-query-report__modal-text">
          {{ restartTasksText }}
        </p>
      </template>
    </sm-modal>
  </div>
</template>

<script>
// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmModal from '@/components/common/modals/SmModal.vue';
import SmTooltip from '@/components/common/SmTooltip.vue';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('requestQueryReport');

export default {
  name: 'RequestQueryReport',

  components: {
    SmEditableList,
    SmIcon,
    SmModal,
    SmTooltip,
  },

  data() {
    return {
      controllerName: 'RequestQueryReport',
      tableCaption: 'Сводный отчет по очереди заданий',
      breadcrumbs: [
        {
          text: 'Мониторинг',
          route: { name: 'MonitoringMain' },
        },
        {
          text: 'Сводный отчет по очереди заданий',
        },
      ],
      tableHeaders: [
        {
          text: 'Имя базы',
          order: 'baseName',
          alias: 'baseName',
        },
        {
          text: 'Название файла',
          order: 'fileName',
          alias: 'fileName',
        },
        {
          text: 'Исходное имя файла',
          order: 'sourceFileName',
          alias: 'sourceFileName',
        },
        {
          text: 'Добавлено',
          order: 'createdAt',
          alias: 'createdAt',
        },
        {
          text: 'Старт обработки',
          order: 'startedAt',
          alias: 'startedAt',
        },
        {
          text: 'Конец обработки',
          order: 'completedAt',
          alias: 'completedAt',
        },
        {
          text: 'Ошибки',
          order: 'hasErrors',
          alias: 'hasErrors',
        },
        {
          text: 'Выполнена',
          order: 'isCompleted',
          alias: 'isCompleted',
        },
        {
          text: 'Время выполнения',
          order: 'workingTime',
          alias: 'workingTime',
        },
      ],
      showInfoModal: false,
      infoModalText: '',
      showRestartTasks: false,
      restartTasksText: '',
    };
  },

  computed: {
    showLogButton() {
      return this.$ability.checkPermissions(
        'Table',
        this.controllerName,
        'ShowLog'
      );
    },

    showDownloadFileButton() {
      return this.$ability.checkPermissions(
        'Table',
        this.controllerName,
        'DownloadFile'
      );
    },

    showRestartTasksButton() {
      return this.$ability.checkPermissions(
        'Table',
        this.controllerName,
        'RestartTasks'
      );
    },

    showActionButtons() {
      return (
        this.showLogButton ||
        this.showDownloadFileButton ||
        this.showRestartTasksButton
      );
    },

    currentTableHeaders() {
      if (!this.showActionButtons) {
        return this.tableHeaders;
      }
      return [
        ...this.tableHeaders,
        {
          alias: 'actionButtons',
        },
      ];
    },
  },

  methods: {
    ...mapActions(['showLog', 'restartTasks', 'downloadFile']),

    onShowInfo(id, name) {
      this.showLog({ id, name }).then((result) => {
        this.infoModalText = result;
        this.showInfoModal = true;
      });
    },

    onCloseInfoModal() {
      this.showInfoModal = false;
      this.infoModalText = '';
    },

    onDownloadFile(taskid) {
      this.$notify({
        header: 'Файл формируется.',
        type: 'success',
        timer: 5000,
      });
      this.downloadFile({ taskid });
    },

    onShowRestartTasks(id) {
      this.restartTasks([id]).then((result) => {
        this.restartTasksText = result[0].comment;
        this.showRestartTasks = true;
      });
    },

    onCloseRestartTasks() {
      this.$refs.smEditableList.fetchList();

      this.showRestartTasks = false;
      this.restartTasksText = '';
    },

    refreshTable() {
      this.$refs.smEditableList.fetchList();
    },
  },
};
</script>

<style lang="scss">
.request-query-report__list-buttons {
  width: 120px;
}

.request-query-report__modal-text {
  word-wrap: break-word;
}
</style>
